import styles from "@/apps/Frictionless/MagicLink/MagicLink.module.css";
import React, {useState} from "react";
import Dorsia from "@/assets/images/Dorsia.svg?react";
import useSWRMutation from 'swr/mutation'
import Config from "@/lib/Config.ts";
import PhoneInputWithCountrySelect, {Country} from "react-phone-number-input/min";
import {useAtom} from "jotai/index";
import {PhoneAtom} from "@/apps/DirectLink/lib/state.ts";

const postPhone = async ([path, requestBody]) => {
    const url = new URL(`${Config.data.uri.api}${path}`);
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        referrerPolicy: "strict-origin-when-cross-origin",
        headers: {
            'Content-Type': 'application/json',
            'X-Dorsia-Client': `widget build:${Config.data.build},environment:${Config.data.environment}`,
            'Accept': 'application/json',
            'X-API-Version': '20240524',
        },
        body: JSON.stringify(requestBody)
    })

    if (!response.ok) {
        throw new Error('Failed to fetch');
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const jsonResponse = await response.json();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    return jsonResponse?.data ? jsonResponse.data : jsonResponse;
}

const MagicLink: React.FC = () => {

    const [sentLink, setSentLink] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [phone, setPhone] = useAtom(PhoneAtom);
    const [number, setNumber] = useState<string>(phone?.formatNational() ?? '');
    const [country, setCountry] = useState<Country>('US');

    const {trigger} = useSWRMutation(['v20240524/magic-link-login', {phone: number}], {
        fetcher: postPhone,
        onError: () => {
            setError(true);
        },
        onSuccess: () => {
            setError(false);
            setSentLink(true);
        }
    });

    const onSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        void trigger();
    }

    const onPhoneCountryChange = (country: Country) => {
        setCountry(country);
    }

    const onPhoneNumberChange = (number: string) => {
        setNumber(number);
    }

    return (
        <div className={styles.section}>
            <Dorsia className={styles.dorsia}/>

            <hr/>

            <div className="content">
                <div className="section">
                    {error && <h3 className={styles.error}>Sorry, this number does not match a Dorsia booking. Please check your number and try again.</h3>}
                    {sentLink && !error && <h3>Check your mobile number for the magic link</h3>}
                    {!sentLink && <h3>Enter the mobile number on your reservation</h3>}
                    {(!sentLink || error) &&
                        (
                            <form className="section" onSubmit={onSubmit}>
                                <div className={styles.field}>
                                    <label>Phone Number</label>
                                    <PhoneInputWithCountrySelect
                                        placeholder="Enter phone number"
                                        value={number}
                                        country={country ?? 'US'}
                                        defaultCountry="US"
                                        onChange={onPhoneNumberChange}
                                        onCountryChange={onPhoneCountryChange}
                                    />
                                </div>

                                <input type="submit" className="nextButton" disabled={number === ''}
                                       value={`Next`}/>
                            </form>)
                    }
                </div>
            </div>
        </div>
    )
}

export default MagicLink;
